import { HeartIcon } from '@kindlyhuman/component-library';
import { useState } from 'react';
import { useRemoveFavoritePeerWithParams, useAddFavoritePeerWithParams } from '../../hooks/usePeers';

interface FollowPillProps {
  isFollowing: boolean;
  listenerRoleId: number;
}

export const FollowPill: React.FC<FollowPillProps> = ({ isFollowing: initialIsFollowing, listenerRoleId }) => {
  const removeFavoritePeer = useRemoveFavoritePeerWithParams();
  const addFavoritePeer = useAddFavoritePeerWithParams();
  const [optimisticFollowing, setOptimisticFollowing] = useState<boolean | null>(null);
  const isFollowing = optimisticFollowing !== null ? optimisticFollowing : initialIsFollowing;
  const handleFollowing = async () => {
    setOptimisticFollowing(!isFollowing);

    if (isFollowing) {
      await removeFavoritePeer.mutateAsync(listenerRoleId);
    } else {
      await addFavoritePeer.mutateAsync(listenerRoleId);
    }
  };

  return (
    <div
      className="w-24 border border-[#081d40] rounded-full flex px-2 items-center gap-2 text-sm font-texta antialiased leading-normal hover:cursor-pointer"
      onClick={handleFollowing}
    >
      <HeartIcon width={18} color="#081d40" fill={isFollowing ? 'true' : 'false'} />{' '}
      {isFollowing ? 'Following' : 'Follow'}
    </div>
  );
};
