import { Redirect, useParams } from 'react-router';
import { useClientCode } from '../hooks/useClientCode';
import { ROUTE_PATH } from './route-paths';

export const GroupIdRouter = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const { data: clientCode, isLoading } = useClientCode({
    partnerCode: groupId,
  });

  // we absolutely need this to let useClientCode complete before redirecting
  if (isLoading) {
    return <></>;
  }

  clientCode && sessionStorage.setItem('clientCode', groupId);

  return <Redirect from={ROUTE_PATH.WELCOME_PAGE} to={ROUTE_PATH.WELCOME_PAGE} />;
};
