import React from 'react';
import { useVimeoPlayer } from '../experienceComponents/useVimeoPlayer';
import { Experience } from '../../hooks/useExperiences';
import { ThumbsDownIcon, ThumbsUpIcon } from '../../assets';
import { useAudioPlayer } from '../experienceComponents/useAudioPlayer';

type VimeoVideoProps = {
  experience: Experience;
  showRating?: boolean;
  width?: number;
};

const VimeoVideo: React.FunctionComponent<VimeoVideoProps> = ({
  experience,
  showRating = true,
  width = undefined,
}): JSX.Element => {
  const playerRef = useVimeoPlayer(experience, width);
  const { experienceRating, handleRatingClick } = useAudioPlayer(experience);

  return (
    <div className="flex-1 flex flex-col justify-center items-center">
      <div ref={playerRef}></div>
      <script src="https://player.vimeo.com/api/player.js"></script>
      {showRating && (
        <div className="flex items-start gap-x-2 py-2 cursor-pointer">
          <ThumbsUpIcon onClick={() => handleRatingClick(1)} isFill={experienceRating > 0} />
          <ThumbsDownIcon onClick={() => handleRatingClick(-1)} isFill={experienceRating < 0} />
        </div>
      )}
    </div>
  );
};

export const VimeoVideoSkeleton: React.FunctionComponent = () => {
  return (
    <div className="flex-1 flex flex-col justify-center items-center">
      <div className="h-90 w-45 bg-gray-300 animate-pulse"></div>
    </div>
  );
};

export default VimeoVideo;
