import { Tag } from '../../../components/common/tag';
import { PeerCardExperience } from './experiences-section';
import { useAudioPlayer } from '../../../components/experienceComponents/useAudioPlayer';
import { AudioControls } from '../../../components/experienceComponents/audio-controls';
import { AudioExperience, ThumbsDownIcon, ThumbsUpIcon, VideoExperience } from '../../../assets';
import { PlayIcon } from '@kindlyhuman/component-library';

export interface ExperienceCardProps {
  experience: PeerCardExperience;
  handleOpenVideoModal: (experience: PeerCardExperience) => void;
}

export const ExperienceCard = ({ experience, handleOpenVideoModal }: ExperienceCardProps) => {
  const {
    audioRef,
    paused,
    percent,
    experienceRating,
    audioStopAll,
    onPlay,
    onPause,
    onTimeUpdate,
    handleRatingClick,
  } = useAudioPlayer(experience);

  return (
    <div id="experience-card" className="w-[336px] font-['Manrope'] bg-white rounded-[10px] p-4">
      <audio ref={audioRef} src={experience.audio_url} onTimeUpdate={onTimeUpdate} onPlay={onPlay} />
      <div id="tag-container" className="pb-4 flex gap-x-2 gap-y-2 flex-wrap">
        {experience.tag_names?.slice(0, 1).map((tag) => <Tag key={tag} tag={tag} />)}
        <img
          src={experience.video_id ? VideoExperience : AudioExperience}
          className="flex h-4"
          alt="Video Experience"
        />
      </div>
      <div className="mb-4 w-[301px] text-[#222833] text-base  leading-[21px] line-clamp-5 overflow-hidden text-wrap truncate">
        {experience.admin_summary || experience.excerpt}
      </div>
      {experience.audio_url && (
        <AudioControls
          paused={paused}
          onPlay={onPlay}
          onPause={onPause}
          percent={percent}
          experienceRating={experienceRating}
          handleRatingClick={handleRatingClick}
          duration={experience.duration}
          audioStopAll={audioStopAll}
        />
      )}
      {experience.video_id && (
        <div className={`flex flex-nowrap gap-x-4 items-center justify-between`}>
          <div className="w-10 h-10 rounded-full bg-gray-50 border-gray-100 left-5 inline-flex">
            <button
              data-testid="play-experience"
              className="cursor-pointer m-auto"
              onClick={() => {
                handleOpenVideoModal(experience);
              }}
            >
              <PlayIcon />
            </button>
          </div>
          <div className="flex gap-x-2 cursor-pointer">
            <ThumbsUpIcon onClick={() => handleRatingClick(1)} isFill={experienceRating > 0} />
            <ThumbsDownIcon onClick={() => handleRatingClick(-1)} isFill={experienceRating < 0} />
          </div>
        </div>
      )}
    </div>
  );
};
