import { DialCare, OpynLogo, RecuroLogo, Walmart } from '../../../assets';
import useAuth from '../../../hooks/useAuth';
import { ProviderName, useProviderPlan, useSsoRedirect } from '../../../hooks/useExternalProviderPlans';
import { Features, useFeatureFlag } from '../../../hooks/useFeatureFlag';
import getPartnerResourceConfig from '../partner-resource-config';

export const useProviderDetails = (providerType?: string) => {
  const { user } = useAuth();
  const { providerPlan, providerName, providerTypes, isLoading: providerPlanLoading } = useProviderPlan();

  const isLoading = user?.isLoading || providerPlanLoading;
  const clientLogoUrl = user?.caller_role.active_subscription?.package?.client?.comms_logo_file_url;
  const partnerResourceConfig = getPartnerResourceConfig(user, providerPlan);

  const recuroSso = useFeatureFlag(Features.RECURO_SSO);

  // URL for Recuro and SSO providers
  let recuroLink;
  if (providerType === 'crisis') {
    // Recuro is the only provider that has a crisis benefits, which uses a different URL
    recuroLink = 'https://kindlyhuman.mysupportid.com/';
  } else if (recuroSso.enabled) {
    recuroLink = `${process.env.REACT_APP_KEYCLOAK_IDP_URL}/realms/${process.env.REACT_APP_KEYCLOAK_IDP_REALM}/protocol/saml/clients/idp-init`;
  } else {
    // Once we go live with Keycloak, we can remove this else block
    recuroLink = 'https://member.recurohealth.com';
  }
  const isSsoEnabled =
    providerName !== 'recuro' &&
    (providerType === 'primary' || providerType === 'counseling' || providerType === 'urgent');
  const {
    data: ssoLink,
    isLoading: ssoLinkLoading,
    error: ssoError,
  } = useSsoRedirect(user?.id || 0, isSsoEnabled, providerType!);
  const url = providerName === 'recuro' ? recuroLink : ssoLink?.redirect_url;
  const urlDescription = `Visit ${providerName} online`;

  // Provider-specific disclaimers && phone numbers
  const providerDetails: Record<
    ProviderName,
    { phoneNumber?: string; phoneNumberText?: string; disclaimerBlock: JSX.Element }
  > = {
    dialcare: {
      disclaimerBlock: (
        <>
          <div className="w-80 p-8 bg-[#F6F6F6] rounded-lg shadow border border-neutral-200 grid place-items-center">
            <img className="min-w-full mb-3" src={DialCare} alt="Dialcare" />
          </div>
          <div className="text-[#222833] text-sm font-medium font-['Manrope'] leading-[21px]">
            THIS PLAN IS NOT INSURANCE and is not intended to replace health insurance.
          </div>
        </>
      ),
    },
    me_md: {
      disclaimerBlock: (
        <>
          <div className="w-80 p-8 bg-[#F6F6F6] rounded-lg shadow border border-neutral-200 grid place-items-center">
            <img className="min-w-full mb-3" src={Walmart} alt="MeMD" />
          </div>
          <div className="text-[#222833] text-sm font-medium font-['Manrope'] leading-[21px]">
            Walmart Health is comprised of independent physician practices. Walmart Health Virtual Care (WHVC) offers
            talk therapy services via telehealth to patients nationwide. Telehealth services may vary by state.
            Telehealth services are provided in accordance with state law by licensed health care professionals, subject
            to the licensed professionals’ judgment. Talk therapy providers do not write prescriptions. WHVC is not an
            insurance product.
          </div>
        </>
      ),
    },
    opyn: {
      phoneNumber: '18666686272',
      phoneNumberText: '1-866-668-6272',
      disclaimerBlock: (
        <>
          <div className="w-80 p-8 bg-[#F6F6F6] rounded-lg shadow border border-neutral-200 grid place-items-center">
            <img className="min-w-full mb-3" src={OpynLogo} alt="Opyn" />
          </div>
          <div className="text-[#222833] text-sm font-medium font-['Manrope'] leading-[21px]">
            PLUS services are provided independently by Opyn Live.
          </div>
        </>
      ),
    },
    recuro: {
      phoneNumber: providerType && providerType === 'crisis' ? '8884915228' : '8556732876',
      phoneNumberText: providerType && providerType === 'crisis' ? '888-491-5228' : '855-6RECURO (855-673-2876)',
      disclaimerBlock: (
        <>
          <div className="w-80 p-8 bg-[#F6F6F6] rounded-lg shadow border border-neutral-200 grid place-items-center">
            <img className="min-w-full mb-3" src={RecuroLogo} alt="Recuro" />
          </div>
          <div className="text-[#222833] text-sm font-medium font-['Manrope'] leading-[21px]">
            PLUS services are provided independently by Recuro Health.
            <br />
            <br />© 2023 Recuro Health, Inc. All rights reserved. Recuro Health, Recuro, and the Recuro logo are
            trademarks of Recuro Health, Inc. and may not be used without written permission. Recuro Health does not
            guarantee that a physician will write a prescription, and physicians do not prescribe DEA controlled
            substances, non-therapeutic drugs and certain other drugs which may be harmful because of their potential
            for abuse. Recuro Health affiliated physicians reserve the right to deny care for potential misuse of
            services. Medical services provided by physicians are subject to their professional judgment. Recuro Health
            operates subject to state regulation and some services may not be available in certain states.
          </div>
        </>
      ),
    },
  };

  return {
    clientLogoUrl,
    disclaimerBlock: providerDetails[providerName!]?.disclaimerBlock || null,
    isLoading,
    partnerResourceConfig,
    phoneNumber: providerDetails[providerName!]?.phoneNumber || undefined,
    phoneNumberText: providerDetails[providerName!]?.phoneNumberText || undefined,
    providerName,
    providerTypes,
    ssoLinkLoading: isSsoEnabled ? ssoLinkLoading : undefined,
    ssoError,
    url,
    urlDescription,
  };
};

export interface ProviderDataProps {
  additionalDisclaimer?: string;
  commonUses?: string[];
  description: string;
  onlineAccessText?: string;
  providerType?: string;
}

export const ProviderDescription: React.FC<ProviderDataProps> = ({
  additionalDisclaimer,
  commonUses,
  description,
  onlineAccessText,
  providerType = undefined,
}) => {
  const { phoneNumberText, disclaimerBlock } = useProviderDetails(providerType);
  return (
    <div className="flex flex-col gap-y-4">
      <p>{description}</p>
      {commonUses && (
        <div>
          <span className="font-bold">COMMON USES:</span>
          <ul>
            {commonUses.map((commonUse, index) => (
              <li key={index}>{commonUse}</li>
            ))}
          </ul>
        </div>
      )}
      {onlineAccessText && (
        <div>
          <span className="font-bold">ONLINE ACCESS:</span> {onlineAccessText}
        </div>
      )}
      {phoneNumberText && (
        <div>
          <span className="font-bold">PHONE ACCESS:</span> Schedule a visit by phone by calling{' '}
          <div>{phoneNumberText}</div>
        </div>
      )}
      {additionalDisclaimer && (
        <div className="text-[#222833] text-sm font-medium font-['Manrope'] leading-[21px]">{additionalDisclaimer}</div>
      )}
      {disclaimerBlock && disclaimerBlock}
    </div>
  );
};
