import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { axiosGetV3 } from '../api/axios-handler';

export const featureFlagKeys = {
  featureFlags: ['featureFlags'] as const,
};

export interface FeatureFlags {
  count: number;
  data: { [key: string]: FeatureFlag };
}

export interface FeatureFlag {
  created_at: Date;
  description: string;
  enabled: boolean;
  id: number;
  key: string;
}

// NOTE: this is manually synced (AKA copypasta) from app/models/feature_flags.py
export enum Features {
  MULTI_FACTOR_AUTHENTICATION = 'MULTI_FACTOR_AUTHENTICATION',
  MWA_KEYCLOAK_LOGIN = 'MWA_KEYCLOAK_LOGIN',
  CALL_IN_EARLY = 'CALL_IN_EARLY',
  VIDEO_EXPERIENCE = 'VIDEO_EXPERIENCE',
  RECURO_SSO = 'RECURO_SSO',
}

const getFeatureFlags = async (): Promise<FeatureFlags> => {
  return await axiosGetV3(`/feature_flags/me`, {}).then((response: AxiosResponse<FeatureFlags>) => response.data);
};

export const useFeatureFlag = (key: keyof typeof Features) => {
  const queryClient = useQueryClient();
  const query = useQuery<FeatureFlags>(featureFlagKeys.featureFlags, () => getFeatureFlags(), {
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
  });

  return {
    ...query,
    enabled: !!query.data?.data[key]?.enabled,
    setEnabled: (newValue: Boolean) => {
      queryClient.setQueryData(['featureFlags'], {
        ...query.data,
        data: {
          ...query.data?.data,
          [key]: {
            ...query.data?.data?.[key],
            enabled: newValue,
          },
        },
      });
    },
  };
};
