import { useCallback, useEffect, useRef, useState } from 'react';
import Player from '@vimeo/player';
import { useListenerAudioPlayMutation, useListenerAudioPlaybackMutation } from '../../hooks/useListenerAudio';
import { Experience } from '../../hooks/useExperiences';
import { analytics, EventTypes } from '@kindlyhuman/component-library';

export const useVimeoPlayer = (experience: Experience, width?: number) => {
  const playerRef = useRef<HTMLDivElement>(null);
  const vimeoPlayer = useRef<Player | null>(null);
  const playMutation = useListenerAudioPlayMutation();
  const playBackMutation = useListenerAudioPlaybackMutation();
  const [hasBeenPlayed, setHasBeenPlayed] = useState<boolean>(false);
  const lastReportedPercentageRef = useRef<number | null>(null);
  const currentPlaybackIdRef = useRef<number>();
  const videoId = experience.video_id!;

  const handlePlay = useCallback(
    (data: { percent: number }) => {
      if (hasBeenPlayed) return;
      setHasBeenPlayed(true);

      if (data.percent === 0) {
        analytics.trackEvent(EventTypes.PLAY_EXPERIENCE, experience.id);
        playMutation.mutate(
          {
            audioId: experience.id,
            listenerId: experience.listener_role_id,
          },
          {
            onSuccess: (listenerAudioPlayback) => {
              currentPlaybackIdRef.current = listenerAudioPlayback.id;
            },
          },
        );
      }
    },
    [hasBeenPlayed, experience, playMutation],
  );

  const handleTimeUpdate = useCallback(
    (data: { percent: number }) => {
      if (!currentPlaybackIdRef.current) {
        console.error('currentPlaybackId is not set for experience id:', experience.id);
        return;
      }
      const percentage = Math.floor(data.percent * 100);
      if (percentage !== 0 && percentage % 10 === 0 && percentage !== lastReportedPercentageRef.current) {
        lastReportedPercentageRef.current = percentage;
        playBackMutation.mutate({
          audioId: currentPlaybackIdRef.current,
          percentCompleted: percentage,
        });
      }
    },
    [experience.id, playBackMutation],
  );

  useEffect(() => {
    if (playerRef.current && !vimeoPlayer.current) {
      vimeoPlayer.current = new Player(playerRef.current, {
        id: parseInt(videoId, 10),
        autoplay: false,
        controls: true,
        width: width || 336,
      });

      vimeoPlayer.current.on('play', handlePlay);
      vimeoPlayer.current.on('timeupdate', handleTimeUpdate);
      vimeoPlayer.current.on('error', (error) => {
        console.error('Vimeo Player Error:', error);
      });

      return () => {
        vimeoPlayer.current?.destroy();
        vimeoPlayer.current = null;
      };
    }
  }, [videoId]);

  return playerRef;
};
