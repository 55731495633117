import Toast from '../components/common/PopUpMessage';
import useAuth from '../hooks/useAuth';
import { useNotifications } from '../hooks/useNotifications';
import { AdminRouter } from './admin-router';
import { ActiveCallerRouter, NonActiveCallerRouter } from './caller-router';
import { OnboardingRouter } from './onboarding-router';
import { PeerRouter } from './peer-router';
import { UnauthenticatedRouter } from './unuathenticated-router';
import { useEffect } from 'react';

export const AuthenticatedRouter = () => {
  const { user, logout, setRedirectTo } = useAuth();
  useNotifications();

  useEffect(() => {
    setRedirectTo(null);
  }, []);

  const renderRouter = () => {
    // she doesn't even go here
    if (!user?.caller_role_id) {
      if (user?.listener_role_id) return <PeerRouter />;
      if (user?.administrator_role_id || user?.client_administrator_role_id) return <AdminRouter />;

      // something horrible has happened here
      return <UnauthenticatedRouter />;
    }

    // you aren't an active user.
    if (!user?.caller_role?.active_subscription) {
      Toast.error(`Your member plan has expired. Please contact your organization's administrator to renew your plan.`);
      logout();
      return <NonActiveCallerRouter />;
    }

    // caller needs to onboard
    if (user?.is_partial) {
      return <OnboardingRouter />;
    }

    // you're an active user!
    return <ActiveCallerRouter />;
  };

  return renderRouter();
};
