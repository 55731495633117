import { useMemo } from 'react';
import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';
import { PeerDetailsExperience } from '../../../hooks/usePeers';
import { ExperienceCard } from './experience-card';
import { Experience } from '../../../hooks/useExperiences';

interface PeerExperienceSectionProps {
  experiences: PeerDetailsExperience[];
  listenerName: string;
  listenerRoleId: number;
  isListener: boolean;
  imageUrl: string;
  clientId: number;
  isAvailable: boolean;
  handleOpenVideoModal: (experience: PeerCardExperience) => void;
}

export interface PeerCardExperience extends Experience {
  tag_names?: string[];
}

const PeerExperienceSection: React.FC<PeerExperienceSectionProps> = ({
  experiences,
  listenerName,
  listenerRoleId,
  isListener,
  imageUrl: profilePhoto,
  clientId,
  isAvailable,
  handleOpenVideoModal,
}) => {
  const experienceList = useMemo(
    () =>
      experiences.map((experience) => {
        return {
          admin_summary: experience.admin_summary,
          area_ids: experience.topic_tags.map((tag) => tag.tag.id),
          audio_url: experience.file_url,
          background_tags: [],
          client_id: clientId,
          client_image_url: '',
          duration: experience.duration,
          excerpt: experience.excerpt,
          id: experience.id,
          is_available: isAvailable,
          is_favorite: false, // TODO is_favorite is soft-deprecated, remove here when we remove it from Experience interface
          is_listener: isListener,
          listener_audio_id: experience.id,
          listener_role_id: listenerRoleId,
          name: experience.name,
          peer_type: 'listener',
          profile_photo_square_file_url: profilePhoto,
          rating: experience.rating,
          score: experience.score,
          similarity_score: 0,
          tag_names: experience.topic_tags.map((tag) => tag.tag.name),
          title: experience.title,
          transcription: experience.transcription,
          video_id: experience.video_id,
        };
      }),
    [experiences],
  );

  return (
    <TileContainer
      title={`${listenerName}'s Experiences`}
      childrenTiles={experienceList.map((experience) => (
        <ExperienceCard experience={experience} handleOpenVideoModal={handleOpenVideoModal} />
      ))}
    />
  );
};

export default PeerExperienceSection;
